import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import GetStarted from "../components/CTA/GetStarted"
import ReviewCarousel from "../components/carousel/ReviewCarousel"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { sortReviewsByDate, getPostObj, pluralizeName } from "../utils/utils"
import NuvoImage from "../components/NuvoImage"
var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

const SingleReview = ({ data, pageContext, location }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.singleReview.nodes[0],
    data.singleReviewEs.nodes[0]
  )
  post = data.singleReview.nodes[0]
  language = postObj.language

  const getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  const getStartedDataEs =
    data.allTemplateSettingsJson.nodes[0].getStartedSpanish

  var reviewTitle = post.title
  const reviewPool = data.relatedReviews.nodes
  var referrerPool = data.relatedReviewsProviders.nodes

  var relatedReviews = getRelatedReviews(post, reviewPool)

  relatedReviews = sortReviewsByDate(relatedReviews)
  referrerPool = sortReviewsByDate(referrerPool)

  function getRelatedReviews(singleReview, allReviews) {
    var thisReviewProcedures = getReviewProceduresNames(singleReview)

    // Get an array of the patient's procedures
    function getReviewProceduresNames(review) {
      var thisReviewProcedures = []

      for (let i = 0; i < review.proceduresDone.length; i++) {
        thisReviewProcedures.push(
          review.proceduresDone[i].procedureDone.procedureName
        )
      }
      return thisReviewProcedures
    }

    var reviewPoolWithProceduresNamesArray =
      addProceduresDoneArrayTo(allReviews)

    // Add array of procedures done names to all review objects
    function addProceduresDoneArrayTo(reviews) {
      var reviewPoolWithProceduresNamesArray = []
      // Make an array of just the names of the procedures done
      for (let i = 0; i < reviews.length; i++) {
        let relatedProceduresDone = []
        for (let j = 0; j < reviews[i].proceduresDone.length; j++) {
          relatedProceduresDone.push(
            reviews[i].proceduresDone[j].procedureDone.procedureName
          )
        }
        let patientInfo = reviews[i]
        patientInfo.allProceduresJustNames = relatedProceduresDone
        reviewPoolWithProceduresNamesArray.push(patientInfo)
      }
      return reviewPoolWithProceduresNamesArray
    }

    var relatedReviews = getReviewsWithAnyMatchingProcedures(
      thisReviewProcedures,
      reviewPoolWithProceduresNamesArray
    )

    // Compare patients to see if any of their procedures done match, if so, add to array and return it
    function getReviewsWithAnyMatchingProcedures(
      thisReviewProcedureNamesArray,
      allReviewsWithProcedureNamesArray
    ) {
      let relatedReviews = []
      // Compares the single review's procedures done names with all the reviews' procedures done names, then pushes matches into another array
      let found = false

      for (let i = 0; i < allReviewsWithProcedureNamesArray.length; i++) {
        if (reviewTitle !== allReviewsWithProcedureNamesArray[i].title) {
          found = false

          found = thisReviewProcedureNamesArray.some(r =>
            allReviewsWithProcedureNamesArray[
              i
            ].allProceduresJustNames.includes(r)
          )
          if (found === true) {
            relatedReviews.push(allReviewsWithProcedureNamesArray[i])
            continue
          }
        }
      }
      return relatedReviews
    }

    return relatedReviews
  }

  var relatedProcedures = post.proceduresDone.map((procedure, i) => {
    let buttonText = procedure.procedureDone.procedureName

    // Full-Arch
    // Impacted Teeth
    // Cleft Treatment
    // DO
    // Jaw Surgery
    // TMJ Disorders
    // Extractions
    // Wisdom Teeth

    if (procedure.procedureDone.procedureShortName) {
      buttonText = procedure.procedureDone.procedureShortName
    }

    return (
      <Button
        className={`contained button-number-${i}`}
        key={procedure.procedureDone.procedureUrl}
        buttonText={buttonText}
        href={"/" + procedure.procedureDone.procedureUrl}
      />
    )
  })

  var showOtherReviews = function () {
    if (post.reviewType === "Patient") {
      return hasOtherPatientReviews()
    } else if (post.reviewType === "Provider") {
      return hasOtherReferrerReviews()
    }
  }

  var hasOtherPatientReviews = function () {
    if (relatedReviews.length > 0) {
      return true
    } else {
      return false
    }
  }
  var hasOtherReferrerReviews = function () {
    if (referrerPool.length > 0) {
      return true
    } else {
      return false
    }
  }

  var reviewCarouselHeading = classNames({
    "review-carousel-heading": true
  })

  var backUrl = "/" + data.allUniquePagesJson.nodes[0].title

  const esCTAClass =
    language === "es" ? "patient-review-cta-es" : "patient-review-cta"

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <section
          className="com-content single-review"
          style={{ paddingBottom: 0 }}>
          <Button
            buttonText={language === "es" ? "ATRÁS" : "BACK"}
            goBack
            href={language === "es" ? "/es/historias-de-pacientes/" : backUrl}
          />
          <div className="columns review-intro-text">
            <div className="column is-5"></div>
            <div className="column">
              <h1 style={{ marginTop: 0 }}>{post.heading}</h1>
              <div>
                <h6 className="video-review-head">
                  {language === "es" ? "Revisión de Video" : "Video Review"}
                </h6>
                <span className="review-small-text">
                  {post.youtubeVideoLength}
                </span>
                <span className="review-small-text">{post.reviewLanguage}</span>
              </div>
              <p>{post.subheading}</p>
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                playing={true}
                hasWatchVideo
                language={language}
                buttonClass="contained"
              />
            </div>
            <div className="column is-4"></div>
          </div>

          {post.youtube && (
            <div className="columns review-video-section">
              <div className="column is-1"></div>
              <div className="column has-text-centered">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  playing={true}
                  language={language}
                  hasWatchVideo={false}>
                  <NuvoImage
                    useAR
                    cloudName="nuvolum"
                    publicId={post.mainPhotoPublicId}
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                    className="theater-image"></NuvoImage>
                </TheaterVideo>
              </div>
              <div className="column is-1"></div>
            </div>
          )}

          {language !== "es" && (
            <div className="columns review-quote-section">
              <div className="column is-4"></div>
              <div className="column">
                <h3>{pluralizeName(post.reviewerName)} Story</h3>
                <p className="review-quote-text">"{post.quote}"</p>
                {relatedProcedures.length >= 1 &&
                  post.reviewType !== "Provider" && (
                    <div className="review-related-procedures-buttons">
                      <h5 className="has-text-centered-mobile">
                        Learn More About
                      </h5>
                      <ButtonGroup alignLeft noReverse>
                        {relatedProcedures}
                      </ButtonGroup>
                    </div>
                  )}
              </div>
              <div className="column is-4"></div>
            </div>
          )}

          {showOtherReviews() && language !== "es" && (
            <>
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column">
                  <h3 className={reviewCarouselHeading}>
                    Watch Other Reviews Like {pluralizeName(post.reviewerName)}
                  </h3>
                </div>
              </div>
              <div
                className="carousel-section-match"
                style={{ paddingBottom: "0" }}>
                <ReviewCarousel
                  id="singleReview"
                  noShuffle={true}
                  relatedReviews={
                    post.reviewType === "Patient"
                      ? relatedReviews
                      : referrerPool
                  }
                />
              </div>
            </>
          )}
        </section>
        <GetStarted
          className={`get-started-cta ${esCTAClass}`}
          centerHeading
          headingSideColumnIs={5}
          sideColumnIs={4}
          subheading={
            language === "es"
              ? getStartedDataEs.subheading
              : getStartedData.subheading
          }
          heading={
            language === "es"
              ? getStartedDataEs.heading
              : getStartedData.heading
          }
          paragraph={
            <div
              className="has-text-centered"
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(
                  language === "es"
                    ? getStartedDataEs.blurb
                    : getStartedData.blurb
                )
              )}></div>
          }
          buttonText={
            language === "es"
              ? getStartedDataEs.buttonText
              : getStartedData.buttonText
          }
          buttonUrl={
            language === "es" ? getStartedDataEs.href : getStartedData.href
          }
        />
      </Layout>
    </SharedStateProvider>
  )
}

export default SingleReview

export const query = graphql`
  query ($id: String!, $title: String!) {
    allUniquePagesJson(filter: { template: { eq: "patient-stories" } }) {
      nodes {
        title
      }
    }
    allTemplateSettingsJson(filter: { templateType: { eq: "single-review" } }) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
        }
      }
    }
    singleReview: allReviewsJson(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        schemaPage
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
            procedureShortName
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        youtubeVideoLength
      }
    }
    singleReviewEs: allSpanishReviewsJson(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        schemaPage
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
            procedureShortName
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        youtubeVideoLength
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        mainProcedureDone
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
    relatedReviewsProviders: allReviewsJson(
      filter: {
        reviewType: { eq: "Provider" }
        reviewLanguage: { eq: "ENG" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        mainProcedureDone
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
  }
`
